exports.components = {
  "component---src-templates-academy-tsx": () => import("./../../../src/templates/Academy.tsx" /* webpackChunkName: "component---src-templates-academy-tsx" */),
  "component---src-templates-affiliate-partner-de-tsx": () => import("./../../../src/templates/AffiliatePartnerDe.tsx" /* webpackChunkName: "component---src-templates-affiliate-partner-de-tsx" */),
  "component---src-templates-affiliate-partner-es-tsx": () => import("./../../../src/templates/AffiliatePartnerEs.tsx" /* webpackChunkName: "component---src-templates-affiliate-partner-es-tsx" */),
  "component---src-templates-affiliate-partner-tr-tsx": () => import("./../../../src/templates/AffiliatePartnerTr.tsx" /* webpackChunkName: "component---src-templates-affiliate-partner-tr-tsx" */),
  "component---src-templates-affiliate-partner-tsx": () => import("./../../../src/templates/AffiliatePartner.tsx" /* webpackChunkName: "component---src-templates-affiliate-partner-tsx" */),
  "component---src-templates-affiliate-partner-vi-tsx": () => import("./../../../src/templates/AffiliatePartnerVi.tsx" /* webpackChunkName: "component---src-templates-affiliate-partner-vi-tsx" */),
  "component---src-templates-android-tsx": () => import("./../../../src/templates/Android.tsx" /* webpackChunkName: "component---src-templates-android-tsx" */),
  "component---src-templates-brand-campaign-tsx": () => import("./../../../src/templates/BrandCampaign.tsx" /* webpackChunkName: "component---src-templates-brand-campaign-tsx" */),
  "component---src-templates-brand-campaign-without-tsx": () => import("./../../../src/templates/BrandCampaignWithout.tsx" /* webpackChunkName: "component---src-templates-brand-campaign-without-tsx" */),
  "component---src-templates-career-start-tsx": () => import("./../../../src/templates/CareerStart.tsx" /* webpackChunkName: "component---src-templates-career-start-tsx" */),
  "component---src-templates-competitions-es-tsx": () => import("./../../../src/templates/CompetitionsES.tsx" /* webpackChunkName: "component---src-templates-competitions-es-tsx" */),
  "component---src-templates-competitions-hi-tsx": () => import("./../../../src/templates/CompetitionsHI.tsx" /* webpackChunkName: "component---src-templates-competitions-hi-tsx" */),
  "component---src-templates-competitions-tsx": () => import("./../../../src/templates/Competitions.tsx" /* webpackChunkName: "component---src-templates-competitions-tsx" */),
  "component---src-templates-competitions-vi-tsx": () => import("./../../../src/templates/CompetitionsVI.tsx" /* webpackChunkName: "component---src-templates-competitions-vi-tsx" */),
  "component---src-templates-contests-tsx": () => import("./../../../src/templates/Contests.tsx" /* webpackChunkName: "component---src-templates-contests-tsx" */),
  "component---src-templates-copy-trading-tsx": () => import("./../../../src/templates/CopyTrading.tsx" /* webpackChunkName: "component---src-templates-copy-trading-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/Course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-cr-ft-tsx": () => import("./../../../src/templates/CrFt.tsx" /* webpackChunkName: "component---src-templates-cr-ft-tsx" */),
  "component---src-templates-deposit-bonus-tsx": () => import("./../../../src/templates/DepositBonus.tsx" /* webpackChunkName: "component---src-templates-deposit-bonus-tsx" */),
  "component---src-templates-financial-freedom-tsx": () => import("./../../../src/templates/FinancialFreedom.tsx" /* webpackChunkName: "component---src-templates-financial-freedom-tsx" */),
  "component---src-templates-fn-freedom-sf-tsx": () => import("./../../../src/templates/FnFreedomSf.tsx" /* webpackChunkName: "component---src-templates-fn-freedom-sf-tsx" */),
  "component---src-templates-fn-freedom-tsx": () => import("./../../../src/templates/FnFreedom.tsx" /* webpackChunkName: "component---src-templates-fn-freedom-tsx" */),
  "component---src-templates-hodl-tsx": () => import("./../../../src/templates/Hodl.tsx" /* webpackChunkName: "component---src-templates-hodl-tsx" */),
  "component---src-templates-ios-tsx": () => import("./../../../src/templates/Ios.tsx" /* webpackChunkName: "component---src-templates-ios-tsx" */),
  "component---src-templates-margin-tsx": () => import("./../../../src/templates/Margin.tsx" /* webpackChunkName: "component---src-templates-margin-tsx" */),
  "component---src-templates-new-contests-tsx": () => import("./../../../src/templates/NewContests.tsx" /* webpackChunkName: "component---src-templates-new-contests-tsx" */),
  "component---src-templates-new-copy-trading-tsx": () => import("./../../../src/templates/NewCopyTrading.tsx" /* webpackChunkName: "component---src-templates-new-copy-trading-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/Partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-partners-vietnam-tsx": () => import("./../../../src/templates/PartnersVietnam.tsx" /* webpackChunkName: "component---src-templates-partners-vietnam-tsx" */),
  "component---src-templates-promo-tsx": () => import("./../../../src/templates/Promo.tsx" /* webpackChunkName: "component---src-templates-promo-tsx" */),
  "component---src-templates-register-tsx": () => import("./../../../src/templates/Register.tsx" /* webpackChunkName: "component---src-templates-register-tsx" */),
  "component---src-templates-strategy-manager-tsx": () => import("./../../../src/templates/StrategyManager.tsx" /* webpackChunkName: "component---src-templates-strategy-manager-tsx" */),
  "component---src-templates-trade-on-your-terms-tsx": () => import("./../../../src/templates/TradeOnYourTerms.tsx" /* webpackChunkName: "component---src-templates-trade-on-your-terms-tsx" */),
  "component---src-templates-web-push-tsx": () => import("./../../../src/templates/WebPush.tsx" /* webpackChunkName: "component---src-templates-web-push-tsx" */)
}

